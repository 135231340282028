<template>
    <div class="license-offer__card">
        <div class="license-offer__card-top">
            <h3 class="license-offer__card-title">
                Your Offer № {{ offerSequense + 1 }}
            </h3>
            <div class="license-offer__card-progress">
                <progress-circle-bar :percentages="[offer.progress_stage]" />
            </div>
        </div>
        <div class="license-offer__card-content">
            <div class="license-offer__card-info">
                <div class="license-offer__card-info__label">
                    Status
                </div>
                <div class="license-offer__card-info__value">
                    {{ offer.status.name }}
                </div>
            </div>
            <div class="license-offer__card-info">
                <div class="license-offer__card-info__label">
                    Created at
                </div>
                <div class="license-offer__card-info__value">
                    {{ offer.created_at | formatDate }}
                </div>
            </div>
            <div class="license-offer__card-info">
                <div class="license-offer__card-info__label">
                    Country
                </div>
                <div class="license-offer__card-info__value">
                    {{ getLicenseOfferInfo(offer.countries, offer, 'other_country') }}
                </div>
            </div>
            <div class="license-offer__card-info">
                <div class="license-offer__card-info__label">
                    License Type
                </div>
                <div class="license-offer__card-info__value">
                    {{ getLicenseOfferInfo(offer.licenseTypes, offer, 'other_license_type') }}
                </div>
            </div>
            <div class="license-offer__card-info" v-if="offer.price">
                <div class="license-offer__card-info__label">
                    Total price
                </div>
                <div class="license-offer__card-info__value">
                    {{ parseInt(offer.price) | numberFormat  }}
                </div>
            </div>
        </div>
        <div class="btn-row">
            <button class="btn btn-edit" @click="editLicenseOffer">
                Edit
            </button>
            <button v-if="offer.progress_stage < 20" class="btn btn-delete" @click="deleteLicenseOffer(offer.id, )">
                <Close />
            </button>
        </div>
    </div>
</template>

<script>
import ProgressCircleBar from '@/components/elem/ProgressCircleBar'
import Close from '@/components/img/Close.vue'

export default {
    name: 'LicenseOffer',
    components: {
        ProgressCircleBar, 
        Close
    },
    props: {
        offer:{
            type: Object,
            required: true,
            default: function _default() {
                return {};
            } 
        },
        offerSequense:{
            type: Number,
            required: true,
            default: function _default() {
                return 0;
            } 
        },
    },
    methods:{
        editLicenseOffer() {
            this.$emit('editLicenseOffer')
        },
        deleteLicenseOffer(id){
            const that = this

            if(confirm('Are you sure you want to delete your request?')){
                axios.delete(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/' + id).then(
                function (response) {
                    that.$noty.success("Your request was successfully deleted")

                    that.$emit('spliceOfferItem')
                }).catch(function (error) {
                    that.$noty.error("Your request wasn't delete")
            })
            }
        },
        getLicenseOfferInfo(array, offer, value){
            if(array.length){
                return array.map(item => item.name).join('|')
            }
            
            return offer[value]
        }
    }
}
</script>

<style lang="scss" scoped>
    .license-offer{
        &__card{
            display: flex;
            flex-direction: column;
            gap: 24px;
            border-radius: 0.3125rem;
            border: 0.0625rem solid #ababb2;
            flex: 0 0 30%;
            max-width: 30%;
            width: 30%;
            padding: 1.5rem;
            position: relative;
            &-top{
                padding-bottom: 12px;
            }
            &-progress{
                position: absolute;
                top: 10px;
                right: 10px;
            }
            &-title{
                color: #09090b;
            }
            &-content{
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
            &-info{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                color: #09090b;
                align-items: center;
                &__label{
                    flex: 1 0 35%;
                    max-width: 35%;
                    width: 35%;
                }
                &__value{
                    flex: 1 0 65%;
                    max-width: 65%;
                    width: 65%;
                    text-align: right;
                }
            }
            .btn-row{
                display: flex;
                justify-content: space-between;
                gap: 8px;
            }
            .btn{
                display: flex;
                flex: 0 0 auto;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                min-height: 2.125rem;
                font-size: 1rem;
                cursor: pointer;
                border-radius: 0.3125rem;
                border: 0.125rem solid #1763fb;
                background: #1763fb;
                color: #fff;
            }
            .btn-edit{
                flex-grow: 1;
            }
            .btn-delete{
                background-color: #B03636;
                border-color: #B03636;
                width: 34px;
            }
        }
    }
    .stat-circle{
        circle.bg{
            fill: none;
            stroke: #f1f1f1;
            stroke-width: 2;
        }
        circle.progress{
            fill: none;
            stroke: #2ecc71;
            stroke-width: 2;
            stroke-dasharray: 51 51;
            stroke-dashoffset: -51;
            stroke-linecap: round;
        }
        text{
            font-size: 3px;
            text-anchor: middle;
            fill: #555;
        }
    }
</style>