<template>
	<div class="notifications" :class="{ 'open': dropdown }" @click="dropdown = !dropdown" v-click-outside="dropdownClose">
		<div class="btn btn-notif btn_mark">
      <div v-if="newNotificationsCount && newNotificationsCount !==0" class="number">{{ newNotificationsCount }}</div>
          <Bell class="img"/>
        </div>
        <div class="list">
          <div v-if="notifications.length > 0">
          	<!-- <div v-for="(notification, index) in notifications" :key="index">
          		<div @click="viewIndividualOffer(notification)" v-if="notification.notification.data.type === 'new-individual-offer'" class="notification-msg">{{ textNotification(notification) }}</div>
          	</div>
            <div v-for="(notification, index) in notifications" :key="index">
              <div @click="viewUpdateProfile(notification)" v-if="notification.notification.data.type === 'update-profile'" class="notification-msg">{{ textNotification(notification) }}</div>
            </div>
            <div v-for="(notification, index) in notifications" :key="index">
              <div @click="viewUpdateProfile(notification)" v-if="notification.notification.data.type === 'update-business-information'" class="notification-msg">{{ textNotification(notification) }}</div>
            </div>
            <div v-for="(notification, index) in notifications" :key="index">
              <div @click="viewUpdateRequest(notification)" v-if="notification.notification.data.type === 'update-request'" class="notification-msg">{{ textNotification(notification) }}</div>
            </div> -->
            <div v-for="(notification, index) in notifications" :key="index">
              <div v-if="notification.notification.data.type === 'chat-select-offer'" class="notification-msg">{{ textNotification(notification) }}</div>
              <div v-else-if="notification.notification.data.type === 'chat-change-offer-status'" class="notification-msg">{{ textNotification(notification) }}</div>
              <div @click="goToChat(notification.notification.data.chat_id)" v-if="notification.notification.data.type === 'create-service-chat'" class="notification-msg">{{ textNotification(notification) }}</div>
              <div @click="goToChat(notification.notification.data.chat_id)" v-if="notification.notification.data.type === 'create-offer-chat'" class="notification-msg">{{ textNotification(notification) }}</div>
            </div> 
          </div>
	        <div v-else>
	      	  <div v-if="loading" class="load">
	      		  <CircleLoad />
	      	  </div>
	        </div>
          <div @click="gotoAllNotifications" v-if="!loading" class="all-notifications-btn">All notifications</div>
	    </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Bell from './../../../components/img/Bell.vue'
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: 'notifications',
  components: {
      Bell,
      CircleLoad
  },
  computed: {
    ...mapGetters({
      newNotificationsCount: 'notifications/unreadCount'
    })
  },
  data () {
    return {
      menu: false,
      isLoaded: false,
      viewList: [],
      viewedList: [],
      viewTimeout: null,
      loading: false,
      notifications: [],
      dropdown: false
    }
  },
  methods: {
    gotoAllNotifications () {
      this.$store.dispatch('notifications/gotoAllNotifications')
    },
  	getUnreadNotifications () {
      if (this.loading) {
        return false
      }
      this.loading = true
      return axios.get(process.env.VUE_APP_BACKEND_API_URL + 'notifications-unread', { params: { limit: 10 } })
        .then(response => {
          this.$store.dispatch('notifications/clearNotifications')
          this.notifications = response.data
        })
        .catch(error => {
          console.error('error get unread notifications', error)
        })
        .finally(() => {
          this.loading = false
        })
	  },
	  dropdownClose() {
        this.dropdown = false
      },
      textNotification (notification) {
        let authorName = notification.author.full_name ? notification.author.full_name : notification.author.member_login

      	if (notification.notification.data.type === 'new-individual-offer') {
      		return 'You have received new individual offer'
      	}

        if (notification.notification.data.type === 'update-profile') {
          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
          return 'Your profile was updated: ' + updatedFields
        }

        if (notification.notification.data.type === 'update-business-information') {
          const updatedFields = notification.notification.data.updated_field_labels.join(', ')
          return 'Your business information was updated: ' + updatedFields
        }

        if (notification.notification.data.type === 'update-request') {
          return 'You request was updated'
        }

        const notificationData = notification.notification.data 

        if (notificationData.type === 'chat-select-offer') {
          return authorName + ' chat select ' + notificationData.offer_type + ' in chat with ' + notificationData.participant_names
        }  

        if (notificationData.type === 'chat-change-offer-status') {
            return authorName + ' changed offer status in chat with ' + notificationData.participant_names
        }

        if (notification.notification.data.type === 'create-service-chat') {
            return authorName + ' applied service and created chat'
        }

        if (notification.notification.data.type === 'create-offer-chat') {
            return authorName + ' applied offer and created chat'
        } 

      	return ''
      },
      viewIndividualOffer (notification) {
      	this.$store.dispatch('notifications/newIndividualOffer', notification)
      },
      viewUpdateProfile (notification) {
        this.$store.dispatch('notifications/updateProfile', notification)
      },
      viewUpdateRequest (notification) {
        this.$store.dispatch('notifications/updateRequest', notification)
      },
      goToChat (chatId) {
        this.$router.push({ name: "Chat", params: { chatId: chatId } })
      },
  },
  watch: {
    dropdown (isOpenedMenu) {
      if (isOpenedMenu === true) {
        this.getUnreadNotifications()
      } else {
        this.notifications = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  --left_offset: 2rem;

  position: relative;
  left: -2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: calc(100% + var(--left_offset));
  max-width: calc(100% + var(--left_offset));
  min-height: var(--header_height);
  padding: .5rem 2.5rem;
  padding-left: calc(2.5rem + var(--left_offset));
  background-color: #24292E;

  @media (max-width: 1366px) {
    padding: .5rem 1rem;
    padding-left: calc(1rem + var(--left_offset));
  }

  .btn, a.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 2.5rem;
    border-radius: .25rem;
    //margin: auto .5rem;
    background-color: transparent;

    .img {
      width: 1.5rem;
      height: 1.5rem;
    }

    &_mark {
      ::v-deep svg.img path {
        //fill: #1763fb;
      }
    }

    &_mark {
      .number {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        width: 1rem;
        height: 1rem;
        background-color:#fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
      }
    }

    &-settings {
      background-color: #62CDAC;
      margin-right: 1.5rem;

      @media (max-width: 1024px) {
        margin-right: .5rem;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 1rem;
        height: 1rem;
        margin: .5rem;
      }

      .desc {
        font-size: 1.75rem;
        font-weight: 600;
        margin: .5rem 1rem;
        padding-left: .5rem;
        white-space: nowrap;

        @media (max-width: 1366px) {
          margin: .5rem 0;
          font-size: 1.5rem;
        }

        @media (max-width: 1024px) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search {
      flex-grow: 1;
      position: relative;
      height: 54px;
      // width: 26rem;
      // width: 25vw;
      max-width: 26rem;
      margin: auto 1rem;

      .img {
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .notifications {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: .75rem;
      //margin-left: 1rem;
      cursor: pointer;

      @media (max-width: 1024px) {
        margin-left: .5rem;
      }

      .list {
        min-width: 300px !important;
        min-height: 70px !important;
        display: none;
        position: absolute;
        top: 100%;
        right: -1px;
        min-width: 100%;
        text-align: right;
        border-top: none;
        border-bottom-left-radius: .75rem;
        border-bottom-right-radius: .75rem;
        background-color: #fff;
        z-index: 2;
        border-radius: 0.75rem;
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.4);

        .item {
          padding: 1rem;
          text-decoration: none;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          &:last-child {
            border-bottom-left-radius: .75rem;
            border-bottom-right-radius: .75rem;
          }

          &:hover {
            background-color: #2f363d;
          }
        }
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // background-color: #1c2126;

        .ava {
          border-bottom-left-radius: 0;
        }

        .arrow {
          transform: rotate(-90deg);
        }

        .list {
          display: block;
        }
      }
    }
  }
}

.load {
	display: inline-flex;
	min-width: 10rem;
	min-height: 3.5rem;
	padding: .5rem 2.5rem;
	margin: 1.75rem auto 3rem;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 1.25rem;
	font-weight: 600;
	border: none;
	border-radius: .75rem;
}

.load {
	width: 10rem;
	height: 3rem;

	svg {
	  width: 100%;
	  height: 100%;
	  animation:spin 1s linear infinite;

	  @keyframes spin {
	    100% {
	      transform:rotate(360deg);
	    }
	  }
	}
}

.notification-msg {
    width: 20rem;
    padding: .25rem .75rem;
    color: white;
    font-size: 12px;
    background: linear-gradient(-5deg, #13B497 -100%, #05737A 100%);
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.4);
    border-radius: 14px;
    //margin: 1.5rem;
}

.all-notifications-btn {
    border-radius: 1rem;
    padding: 10px;
    margin-left: 30px;
    background-color: #1763fb;
    text-decoration: unset;
    color: inherit;
    cursor: pointer;
    margin: 2rem 1rem;
}
</style>
