<template>
  <div id="header">
    <div class="left">
      <div class="title">
        <!--<ArrowFlat class="img"/>-->
          <div class="desc">
            <div v-if="$route.meta.isChat" class="chat-wrapper">
              <div class="chat-img">
                <img src="/img/hand.png"></img>
              </div>
              <div>
                N5 Bank Chat
                <div class="chat-desc">direct connection with providers</div>
              </div>
            </div>
            <span v-else>{{ $route.meta.desc }}</span>
          </div>
      </div>
    </div>
    <div class="right">
      <!--<div class="search">
        <input type="text" name="search" id="search" placeholder="Search here">
        <Search class="img"/>
      </div>-->
      <!--<router-link :to="{ name: 'Settings' }" class="btn btn-settings">
        <Settings class="img"/>
      </router-link>-->
      <button @click="bookCall" v-if="calendly_link && calendly_text" class="calendly_btn">
        {{ calendly_text }}
      </button>
      <Notifications />
      <Messages />
      <div
        class="profile" :class="{ 'open': dropdown }"
        @click="dropdown = !dropdown" v-click-outside="dropdownClose"
      >
      <router-link :to="{ name: 'Settings' }" class="btn btn-settings">
        <div class="ava" :style="{ 'background-image': 'url(' + userLogo + ')' }"></div>
        <div class="name">{{ user && user.company_full_name ? 'Your Profile ' + user.company_full_name : 'Your Profile' }}</div>
      </router-link>
        <a @click="logout" href="#" class="item">
          <Logout />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowFlat from '@/components/img/ArrowFlat.vue'
import Logout from '@/components/img/Logout.vue'
import Search from '@/components/img/Search.vue'
import Settings from '@/components/img/Settings.vue'
import Bell from '@/components/img/Bell.vue'
import Chat from '@/components/img/Chat.vue'
import Notifications from './../components/common/notifications/notifications.vue'
import Messages from './../components/common/messages/messages.vue'

export default {
  components: {
    ArrowFlat,
    Logout,
    Search,
    Settings,
    Bell,
    Notifications,
    Messages,
    Chat,
  },
  data() {
    return {
      dropdown: false,
      calendly_link: 'https://calendly.com/n5bank',
      calendly_text: 'Book a call',
    }
  },
  async mounted(){
    await this.getCalendlyData();
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isAdmin () {
      return this.user && this.user.is_admin
    },
    userLogo () {
      return this.user && this.user.logo
        ? process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/origin/' +  this.user.logo
        : '/img/default-avatar.png'
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      window.location.href = process.env.VUE_APP_CABINET_URL
    },
    dropdownClose() {
      this.dropdown = false
    },
    async getCalendlyData(){
      const that = this

      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'settings/calendly_link')
      .then(function ({ data }) {
        that.calendly_link = data.calendly_link
      })
      .catch(function (error) {
        console.log(error);
      })

      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'settings/calendly_text')
      .then(function ({ data }) {
        that.calendly_text = data.calendly_text
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    bookCall(){
      Calendly.initPopupWidget({url: this.calendly_link});
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  --left_offset: 2rem;

  position: relative;
  left: -2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: calc(100% + var(--left_offset));
  max-width: calc(100% + var(--left_offset));
  min-height: var(--header_height);
  padding: .5rem 2.5rem;
  padding-left: calc(2.5rem + var(--left_offset));
  background-color: #fff;
  border-bottom: 1px solid rgba(9,9,11,.1);
  .btn-settings{
    width: auto !important;
  }
  @media (max-width: 1366px) {
    padding: .5rem 1rem;
    padding-left: calc(1rem + var(--left_offset));
  }
  
  .btn, a.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 2.5rem;
    border-radius: .75rem;
    //margin: auto .5rem;
    background-color: transparent;

    .img {
      width: 1.5rem;
      height: 1.5rem;
    }

    &_mark {
      ::v-deep svg.img path {
        fill: #1763fb;
      }
    }

    &_mark::before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 1rem;
      height: 1rem;
      background-color:#1763fb;
      border-radius: 50%;
    }

    &-settings {
      background-color: transparent;
      //margin-right: 1.5rem;

      @media (max-width: 1024px) {
        margin-right: .5rem;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #09090b;

      .img {
        width: 1rem;
        height: 1rem;
        margin: .5rem;
      }

      .desc {
        font-size: 1.75rem;
        font-weight: 600;
        //margin: 0 1rem;
        //padding-left: .5rem;
        white-space: nowrap;

        @media (max-width: 1366px) {
          font-size: 1.5rem;
        }

        @media (max-width: 1024px) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search {
      flex-grow: 1;
      position: relative;
      height: 54px;
      // width: 26rem;
      // width: 25vw;
      max-width: 26rem;
      margin: auto 1rem;

      input#search {
        width: 100%;
        height: 100%;
        color: #09090b;
        background-color: #fff;
        padding: .5rem 3.25rem .5rem 1.25rem;
        margin: auto .5rem;
        border: 1px solid #2F363D;
        border-color: #ababb2;
        border-radius: .75rem;
        box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
        outline: none;
        -webkit-appearance: none;

        &::placeholder {
          color: #B9B9B9;
        }
      }

      .img {
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .profile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px dashed #969ba0;
      border-radius: 3px;
      padding: 6px;
      margin-left: 1.75rem;
      cursor: pointer;

      @media (max-width: 1024px) {
        margin-left: .5rem;
      }

      .ava {
        width: 2.5rem;
        height: 2.5rem;
        //border-radius: .75rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .name {
        margin: 0 .25rem 0 .75rem;
        padding: .25rem .5rem;
        color: #09090b;
      }

      .arrow {
        width: .75rem;
        height: .75rem;
        margin: 0 1rem 0 .25rem;
        transform: rotate(90deg);
      }

      .list {
        display: none;
        position: absolute;
        top: 100%;
        right: -1px;
        min-width: 100%;
        min-width: calc(100% + 2px);
        text-align: right;
        //border: 1px solid #09090b;
        border-top: none;
        //border-bottom-left-radius: .75rem;
        //border-bottom-right-radius: .75rem;
        background-color: #fff;
        z-index: 2;

        .item {
          padding: 1rem;
          text-decoration: none;
          color: #09090b;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          &:last-child {
            border-bottom-left-radius: .75rem;
            border-bottom-right-radius: .75rem;
          }

          &:hover {
            color: #1763fb;
          }
        }
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fff;

        .ava {
          border-bottom-left-radius: 0;
        }

        .arrow {
          transform: rotate(-90deg);
        }

        .list {
          display: block;
        }
      }
    }
  }
}
.calendly_btn{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 12.875rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 2.4375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.3125rem;
  background-color: #1763fb;
  border: 0.0625rem solid #3177ff;
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@keyframes glowing {
  0%,100% {
      background-color: #1763fb;
      box-shadow: 0 0 2px #1763fb
  }
  50% {
      background-color: #3177ff;
      box-shadow: 0 0 24px #3177ff
  }
}
.chat-wrapper {
  display: flex;
  flex-direction: row;
}

.chat-desc {
  font-size: 12px;
}

.chat-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
}

.chat-img img {
  width: 30px;
  height: 30px;
}
</style>
