<template>
  <div class="tbl" ref="tbl">
    <div class="tbl_header">
      <div
        v-for="(i, key) in options.header"
        :key="key"
        class="col"
        :class="i.class ? i.class : ''"
        :style="{ 'width': options.colsWidth[key] }"
      >
        <span>
          {{ i.ttl }}
          <Info v-if="i.info" :pos="i.info.pos" :txt="i.info.txt" />
          <span
            v-if="i.sort"
            class="sort"
            :class="{
              'sort--load': options.sort.loading,
              'sort--asc': options.sort.name === i.sort && options.sort.order === 'asc',
              'sort--desc': options.sort.name === i.sort && options.sort.order === 'desc',
            }"
            @click="sortBy(i.sort)"
          >&#9650;</span>
        </span>
      </div>
    </div>
    <div class="tbl_body">
      <div v-for="(i, key) in options.body" :key="key" class="tbl_row" :class="getIndividualClass(key)" :style="{ 'cursor': chatAppliedOfferType ? 'pointer' : '' }" @click="clickRow(key)">
        <div v-if="isIndividual(key)" class="individual-offer-hint" >Individual offer for you</div>
        <div
          v-for="(el, el_key) in i"
          :key="`w_${el_key}`"
          class="col"
          :style="{ 'width': getCellWidth(el, el_key) }"
        >
          <div @click="apply(el.id, el.applyType)" v-if="getType(el) === 'object' && el.type === 'apply-btn'" class="btn" v-html="el.html"></div>
          <div @click="showBusinessOfferDataset(el.id)" v-else-if="getType(el) === 'object' && el.type === 'business-offer-details'" class="btn" v-html="el.html">
          </div>
          <div @click="applyAccount(el.id, el.applyType, el.gdpr)" v-else-if="getType(el) === 'object' && el.type === 'account-apply-btn'" class="btn" :class="{ 'account-apply-btn-readonly': !el.gdpr }" v-html="el.html"></div>
          <span v-else-if="getType(el) === 'object' && el.type === 'account-gdpr'">
            <div class="checkBox" @click="changeAccountGdpr(el.id, el.serialNumber, el.applyType, el.gdpr)"> 
              <div class="check"><Check v-show="el.gdpr" /></div>
            </div>
          </span>
          <div v-else-if="getType(el) === 'object' && el.type === 'star-id'" class="star-wrapper">
            <span class="star-icon" v-if="el.is_top">
              <Star class="img" />
            </span>
            {{ el.id }}
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'basic'" class="btn" v-html="el.html"></div>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline'">
            <div class="ddln">
              Deadline: <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
            <div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'long_description'">
            <div @click="openLongDescModal(el.value)">
              <InfoIcon class="img" />
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline-balance'">
            <div class="ddln">
              Deadline: <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
            <!--<div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>-->
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'balance'">
            <!--<div class="ddln">
              Deadline: <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>-->
            <!--<div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>-->
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline_2'">
            <div class="ddln">
              <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
            <div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline_info'">
            <div class="ddln">
              <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'cancel'">
            <div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <div v-else-if="getType(el) === 'object' && el.type === 'transactions'">
            <div>
              <small :style="{ color: +el.total >= +el.price ? 'green' : '' }">
                {{ number_format(el.total, 2, '.', ' ') }}€
              </small>
            </div>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'deadline_lite'" class="ddln">
            <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
          </div>
          <span v-else-if="getType(el) === 'object' && el.type === 'info'" >
            {{ el.html }}
            <Info :pos="el.info.pos" :txt="el.info.txt" />
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'del'" class="del">
            <div class="btn btn_close"><Close /></div>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'notification'" class="notification">
            <span @click="showNotificationDetails(el.notificationType, el.notification)">{{ el.text }}</span>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'hidden-id'" style="display: none">
            {{ el.id }}
          </span>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit'" class="btn-wrap">
            <button class="small-btn" @click="editItem(el.id)">
              <Pen></Pen>
            </button>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete'" class="btn-wrap">
            <button class="small-btn" @click="editItem(el.id)">
              <Pen></Pen>
            </button>
            <button class="small-btn delete" @click="deleteItem(el.id)">
              <Close></Close>
            </button>
          </div>

          <span v-else-if="(getType(el) === 'object' && el.type !== 'individual' ) || getType(el) !== 'object'">
            <span v-if="$route.name != 'Accounts'"><truncate clamp="..." :length="30" less="Show Less" :text="el"></truncate></span>
            <span v-else v-html="el" :style="[el && el.length > 60 ? {'font-size': '12px'} : {}]"></span>
          </span>
        </div>
      </div>
      <div v-if="options.footer && options.footer.type === 'Total Price' " class="tbl_row tbl_row-footer">
        <div class="tp">Total Price: <span class="val">{{ options.footer.val }}</span></div>
      </div>
    </div>
    <div class="tbl_footer">
      <pagination
        v-if="options.paginationData"
        :data="options.paginationData"
        @pagination-change-page="paginationChangePage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination'
import Info from '@/components/elem/Info.vue'
import Close from '@/components/img/Close.vue'
import Pen from '@/components/img/Pen.vue'
import Star from '@/components/img/Star.vue'
import { mapMutations } from 'vuex';
import truncate from 'vue-truncate-collapsed'
import Check from '@/components/img/Check.vue'
import InfoIcon from '@/components/img/Info.vue'

export default {
  name: 'Table',
  components: {
    Pagination,
    Info,
    Close,
    Pen,
    truncate,
    Check,
    InfoIcon,
    Star
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    chatAppliedOfferType: {
      type: String,
      required: false,
      default: false
    }
  },
  watch: {
    'myProfileForm.registration_country': function (countryId) {
      this.setCountryName(countryId)
    },
    options: function (options) {
      console.log('options')
      console.log(options)
    }
  },
  methods: {
    ...mapMutations({
      setChatOffer: 'SET_CHAT_OFFER',
    }),
    getCellWidth(data, key) {
      let w = this.options.colsWidth[key]

      if (this.getType(data) === 'object' && (data.type === 'deadline' || data.type === 'deadline_2')) {
        w = `calc(${this.options.colsWidth[key]} + ${this.options.colsWidth[key + 1]})`
      }

      return w
    },
    apply (id, applyType) {
      if (this.options.comingSoon) return

      this.$emit('apply', {
        id: id,
        applyType: applyType,
      })
    },
    showBusinessOfferDataset(id){
      this.$emit('show-business-offer-detail', {
        id: id,
      })
    },
    editItem(id) {
      this.$emit('edit', {
        id: id,
      })
    },
    deleteItem(id) {
      this.$emit('delete', {
        id: id,
      })
    },
    applyAccount (id, applyType, gdpr) {
      if (gdpr) {
        if (this.options.comingSoon) return

        this.$emit('apply', {
          id: id,
          applyType: applyType,
        })
      }
    },
    changeAccountGdpr (id, serialNumber, type, gdpr) { 
      this.$emit('change-account-gdpr', {
        id: id,
        serialNumber: serialNumber,
        type: type,
        gdpr: gdpr,
      })
    },
    openLongDescModal(value){
      this.$store.commit('setModal', {
        template: 'long-description',
        options: {
          value: value
        }
      })
    },
    paginationChangePage (page) {
      if (this.options.comingSoon) return

      this.$emit('change-page', page)
      this.$emit('scroll-top', this.$refs.tbl)
    },
    getIndividualClass (index) {
      if (this.options.individual && this.options.individual.length > 0 && this.options.individual[index]) {
        return 'individual'
      }
      return ''
    },
    isIndividual (index) {
      if (this.options.individual && this.options.individual.length > 0 && this.options.individual[index]) {
        return true
      }
      false
    },
    sortBy (name) {
      if (this.options.sort.loading) return

      this.$emit('sort', {
        obj: this.options.objName,
        sort: name
      })
    },
    clickRow (row) {
      if (this.chatAppliedOfferType.length > 0) {
        for (var prop in this.options.body[row]) {
            if (Object.prototype.hasOwnProperty.call(this.options.body[row], prop)) {
                const p = this.options.body[row][prop]
                if (p.type && p.type == 'hidden-id') {
                  this.setChatOffer({
                    id: p.id,
                    type: this.chatAppliedOfferType,
                    time: +Date.now()
                  })
                  this.$emit('close',)
                }
            }
        }
      }
    },
    showNotificationDetails (type, notification) {
      if (type === 'create-service-chat') {
        this.$router.push({ name: "Chat", params: { chatId: notification.notification.data.chat_id } })
      }
      if (type === 'create-offer-chat') {
        this.$router.push({ name: "Chat", params: { chatId: notification.notification.data.chat_id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  background: transparent !important;
  .btn-wrap{
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: end;
  }
  .small-btn{
    width: 32px;
    height: 32px;
    border-radius: 3px;
    background: #1763fb;
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    &.delete{
      background: #B03636;
    }
    svg{
      fill: #fff;
    }
  }
  &_header {
    color: #fff !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #1763fb;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .col {
      // padding: 0.75rem 2px;
      padding: .5rem 2px;
      font-size: 14px;
      min-height: 3.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.gradient_btn {
        padding: 6px;
        position: relative;

        $margin: 4px;
        &::before {
          content: '';
          position: absolute;
          top: $margin; right: $margin; bottom: $margin; left: $margin;
          // background: linear-gradient(113.24deg, #13B497 16%, #05737A 106.71%);
          background: #1763fb;
          filter: blur(1px);
          border-radius: 14px;
        }

        span {
          position: relative;
          width: 100%;
          min-height: 2.75rem;
          padding: .25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1763fb;
          border-radius: 14px;
        }
      }

      .sort {
        font-size: 22px;
        line-height: 0.3;
        cursor: pointer;
        padding: 0 0.25rem;
        display: inline-flex;
        align-items: baseline;
        color: #fff;

        &--asc {
          transform: rotate(180deg);
          color: #fff;
        }

        &--desc {
          color: #fff;
        }

        &--load {
          color: rgba(128, 128, 128, 0.4);
          cursor: default;
        }
      }
    }
  }

  &_body {
    .individual {
        background-color: #ff4c4c;
    }

    .tbl_row {
      border: 1px solid #555e76;
      position: relative;
      color: #09090b !important;

      .individual-offer-hint {
        position: absolute;
        left: -52px;
        top: -10px;
        background-color: #13B497;
        font-size: 16px;
        padding: 0.4rem;
        border-radius: 6px;
        color: white;
      }
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: none;
      }

      .col {
        // position: relative;
        text-align: center;
        color: #09090b !important;
        padding: 6px 2px;
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          width: calc(100% - 1.5rem);
          color: #fff;
          padding: .25rem .25rem;
          background: #1763fb;
          border-radius: 6px;

          &.info {
            width: 1rem;
            padding: 0;
            background: transparent;
          }
        }

        .ddln {
          // position: absolute;
          // top: 50%;
          // right: 10%;
          // transform: translateY(-50%);
          width: calc(100% - 1.5rem);
          //background: #393b41;
          border-radius: 6px;

          &_val {
            font-size: 24px;
            color: #1763fb;
          }

          &_desc {
            font-size: 14px;
          }
        }

        .del {
          .btn_close {
            width: 2rem;
            height: 2rem;
            padding: .25rem;
            background-color: #B03636;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 45%;
              height: 45%;
            }
          }
        }
        .ml-3 {
          margin: 0 13px;
        }
      }

      &-footer {
        justify-content: flex-start;
        padding-left: 20vw;

        .tp {
          font-size: 1.25rem;
          padding: .5rem 2rem;
          margin: .5rem;
          display: flex;
          align-items: baseline;
          justify-content: center;
          background-color: transparent;
          border-radius: 6px;

          .val {
            color: #1763fb;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }

  &_footer {
    ::v-deep .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      //background: rgba(19, 180, 151, 0.1);
      border-radius: 14px;

      .page-item {
        margin: .75rem .5rem;

        a {
          width: 2.25rem;
          max-width: 2.25rem;
          height: 2.25rem;
          max-height: 2.25rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: .5rem;
          font-size: 1.25rem;
          color: #1763fb;
          text-decoration: none;
          text-transform: uppercase;
          //background-color: #2F363D;
          border-radius: 50%;

          .sr-only {
            display: none;
          }
        }

        &.active {
          a {
            color: #ffffff;
            background-color: #1763fb;
            font-weight: 800;
          }
        }
      }
    }
  }
}

.img{
  width: 24px;
  height: 24px;
  fill: #1763fb;
  cursor: pointer;
}

.checkBox {
  display: flex;
  align-items: flex-start;

  .check {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    border: 1px solid #5E7084;
    border-radius: .25rem;
    margin: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ::v-deep svg {
      width: 13px;
      height: 13px;

      path {
        fill: #ffffff;
      }
    }
  }
}

.account-apply-btn-readonly {
    background: #787878 !important;
    cursor: initial;
}
.star-wrapper{
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  .star-icon{
    position: absolute;
    left: 0;
  }
}
</style>
