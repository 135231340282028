var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'sidebar-show': _vm.showSidebar },attrs:{"id":"sidebar"}},[_c('div',{staticClass:"btn btn_burger",class:{ 'btn_burger-show': _vm.showSidebar },on:{"click":function($event){_vm.showSidebar = !_vm.showSidebar}}},[(!_vm.showSidebar)?_c('svg',{staticStyle:{"enable-background":"new 0 0 384 384"},attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 384 384","xml:space":"preserve"}},[_c('rect',{attrs:{"x":"0","y":"277.333","width":"384","height":"42.667"}}),_c('rect',{attrs:{"x":"0","y":"170.667","width":"384","height":"42.667"}}),_c('rect',{attrs:{"x":"0","y":"64","width":"384","height":"42.667"}})]):_c('Close')],1),_c('div',{staticClass:"header",class:{ 'header-show': _vm.showSidebar }},[_c('a',{attrs:{"hhrf":"https://n5bank.com"}},[_c('LogoWhite',{staticClass:"logo"})],1)]),_c('div',{staticClass:"main",class:{ 'main-show': _vm.showSidebar }},[_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"circle"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRateInfo),expression:"!showRateInfo"}],staticClass:"img",style:({ 'background-image': 'url(' + _vm.logo + ')' })})]),_c('div',{staticClass:"desc"},[_c('router-link',{staticClass:"profile-name",attrs:{"to":{name: 'Settings'}}},[_vm._v(" Hello, "+_vm._s((_vm.$store.state.user) ? _vm.$store.state.user.company_full_name : '')+" ")])],1),_c('router-link',{staticClass:"btn btn_balance",attrs:{"to":{ name: 'Balance' }}},[_vm._v(" Balance: "+_vm._s(_vm.number_format(_vm.balance, 2, '.', ' '))+"€ ")])],1),_c('div',{staticClass:"links"},[_c('div',{staticClass:"links__wrapper"},[_c('div',{staticClass:"bg"}),_vm._l((_vm.mainLinks),function(item,key){return _c('router-link',{key:key,staticClass:"item",class:{
            'item-active': _vm.$route.name == item.routeName || _vm.isChildRoute(_vm.$route.name, item.routeName),
            'soon': item.сomingSoon,
            'special': item.class
          },attrs:{"to":{ name: item.routeName }},nativeOn:{"click":function($event){_vm.showSidebar = false}}},[_c(item.imgComponent,{tag:"component",staticClass:"img"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))]),_c('span',{staticClass:"soon_label"},[_vm._v("Coming soon")])],1)})],2),_c('div',{staticClass:"links__wrapper"},_vm._l((_vm.moreLinks),function(item,key){return _c('router-link',{key:key,staticClass:"item",class:{
            'item-active': _vm.$route.name == item.routeName,
            'soon': item.сomingSoon
          },attrs:{"to":{ name: item.routeName }},nativeOn:{"click":function($event){_vm.showSidebar = false}}},[_c(item.imgComponent,{tag:"component",staticClass:"img"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))]),_c('span',{staticClass:"soon_label"},[_vm._v("Coming soon")])],1)}),1)])]),_c('div',{staticClass:"footer",class:{ 'footer-show': _vm.showSidebar }},[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{},[_vm._v(" © 2024 All Rights Reserved "),_c('a',{staticClass:"terms-service-link",attrs:{"target":"_blank","href":"https://n5bank.com/docs/terms.pdf"}},[_vm._v("Terms and Conditions")]),_vm._v(" | "),_c('a',{staticClass:"terms-service-link",attrs:{"target":"_blank","href":"https://n5bank.com/docs/privacy.pdf"}},[_vm._v("Privacy Notice")]),_vm._v(" | "),_c('a',{staticClass:"terms-service-link",attrs:{"target":"_blank","href":"https://n5bank.com/docs/confidentiality.pdf"}},[_vm._v("Confidentiality and Non-disclosure Notice")])])}]

export { render, staticRenderFns }