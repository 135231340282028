<template>
  <div class="text-center">
    <div v-for="(percentage, index) in percentages" :key="index" class="stat-circle" :style="{ width: circleSize + 'px', height: circleSize + 'px' }">
      <svg :width="circleSize" :height="circleSize" viewBox="0 0 20 20">
        <circle class="bg" cx="10" cy="10" r="8"></circle>
        <circle
          class="progress"
          cx="10"
          cy="10"
          r="8"
          :data-percentage="percentage"
          ref="circles"
          :stroke-dasharray="`${circleCircumference} ${circleCircumference}`"
          :stroke-dashoffset="circleOffsets[index]"
          stroke-linecap="round"
        ></circle>
        <text x="50%" y="50%">{{ percentage }}%</text>
      </svg>
    </div>
  </div>
</template>

<script>
import { TweenMax } from 'gsap';

export default {
  props: {
    percentages: {
      type: Array,
      default: () => [0]
    },
    circleSize: {
      type: Number,
      default: 70
    }
  },
  data() {
    return {
      circleCircumference: 51 * Math.PI,
      circleOffsets: []
    };
  },
  mounted() {
    this.calculateOffsets();
  },
  methods: {
    calculateOffsets() {
      const $statCircles = this.$refs.circles;

      for (let i = 0; i < $statCircles.length; i++) {
        const p = parseFloat($statCircles[i].getAttribute('data-percentage'));
        const off = -51 - (51 / 100) * p;
        this.circleOffsets.push(off);

        new TweenMax.to($statCircles[i], 0.4, {
          strokeDashoffset: off
        });
      }
    }
  }
};
</script>

<style>
.stat-circle {
  display: inline-block;
}

.stat-circle svg {
 
}

.stat-circle .bg {
  fill: none;
  stroke: #ababb2;
  stroke-width: 1;
}

.stat-circle .progress {
  fill: none;
  stroke: #1763fb;
  stroke-width: 1;
  stroke-dasharray: 51 51;
  stroke-dashoffset: -51;
  stroke-linecap: round;
}

.stat-circle text {
  font-size: 5px;
  text-anchor: middle;
  fill: #555;
  dominant-baseline: middle;
}
</style>