<template>
    <div class="modal__wrapper">
        <div class="modal__close">
          <div class="btn" @click.prevent="close">
            
          </div>
        </div>
        <div class="modal__header">
          <div class="ttl">
            Information about License
          </div>
        </div>
        <div class="modal__body page-request main">
          <div class="row" v-for="(item, i) in options.value" v-if="item.description">
            <div class="label">
              {{ item.title }}  
            </div>
            <div class="data">
              {{ item.description }}
            </div>
          </div>
          
        </div>
        <div class="modal__footer footer">
          
        </div>
      </div>
  </template>
  
  <script>
  import File from '@/components/img/File.vue'
  import draggable from 'vuedraggable'
  import BlueDownload from '@/components/img/BlueDownload.vue'
  import Close from '@/components/img/Close.vue'
  
  export default {
    components: {
        File,
        draggable,
        BlueDownload,
        Close
    },
    props: {
      options: {
        type: Object,
        required: true,
      },
    },
    data(){
        return {
            
        }
    },
    async created() {
      
    },
    methods: {
        close() {
          this.$emit("close");
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .modal {
    &__header{
      padding: 24px;
      .sbttl{
        font-style: normal !important;
      }
    }
    &__body{
      padding: 0 24px;
    }
    &__wrapper {
      width: 540px;
      max-width: 100%;
    }
  
    &__close {
      .btn::before {
        content: '+';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        color: white;
        font-size: 2.25rem;
        font-weight: 300;
      }
    }
  
    &__body {
      flex-direction: column;
  
      .row {
        width: 100%;
        display: flex;
        align-items: center;
        .data {
          display: flex;  
          flex: 1 0 auto;
          max-width: 100%;
        }
      }
      input, textarea {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 16px 24px !important;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        border-radius: 5px;
        border: 1px solid;
        border-color: #ababb2;
        margin-bottom: 0 !important;
        resize: none;
        &::placeholder {
          color: #09090b;
          opacity: 1; /* Firefox */
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: #09090b;
        }
      }
      textarea{
        height: 120px;
        padding: 4px 24px !important;
      }
  
      select {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 24px !important;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        border-radius: 5px;
        border: 1px solid;
        border-color: #ababb2;
        margin-bottom: 0 !important;
      }
  
      .input-group__col{
        gap: 1rem;
        input{
          width: calc(65% - 1rem);
          max-width: calc(65% - 1rem);
        }
        select{
          width: 35%;
          max-width: 35%;
        }
      }
  
      .label {
        // min-width: 10rem;
        font-weight: bold;
        max-width: 35%;
        flex: 0 0 35%;
        border-right: 1px dashed #ababb2;  
        padding: 12px 12px 12px 0;
      }
  
      .data {
        max-width: 65%;
        flex: 0 0 65%;
        padding-left: 12px;
        .inpt {
          color: var(--c_black);
          outline: none;
          -webkit-appearance: none;
        }
      }
  
      .invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
  
      // .form-control {
      //   width: 100%;
      //   height: 100%;
      //   color: #ffffff;
      //   background-color: #1C2126;
      //   padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      //   margin: auto 0rem;
      //   border: 1px solid #2F363D;
      //   border-radius: 0.75rem;
      //   box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
      //   outline: none;
      //   -webkit-appearance: none;
      // }
    }
  
    &__footer {
      padding: 24px;
      .btn {
        font-size: 18px;
        padding: 1rem 3rem;
        margin: 0;
        background: #1763fb;
        box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
        border-radius: 6px;
      }
    }
  }
  .modal {
    &__header{
      padding: 24px;
      .sbttl{
        font-style: normal !important;
      }
      .ttl{
        padding: 0;
        &:before{
          content: none;
        }
      }
    }
    &__body{
      padding: 0 24px;
    }
    &__wrapper {
      width: 540px;
      max-width: 100%;
    }
  
    &__close {
      .btn::before {
        content: '+';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        color: white;
        font-size: 2.25rem;
        font-weight: 300;
      }
    }
  
    &__body {
      flex-direction: column;
  
      .row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        border-bottom: 1px dashed #ababb2;
        &:last-child{
          border: none;
        }
        .data {
          display: flex;  
          max-width: 65%;
          flex: 0 0 65%;
          padding: 12px 0 12px 12px;
        }
      }
      input, textarea {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 16px 24px !important;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        border-radius: 5px;
        border: 1px solid;
        border-color: #ababb2;
        margin-bottom: 0 !important;
        resize: none;
        &::placeholder {
          color: #09090b;
          opacity: 1; /* Firefox */
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: #09090b;
        }
      }
      textarea{
        height: 120px;
        padding: 4px 24px !important;
      }
  
      select {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 24px !important;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        border-radius: 5px;
        border: 1px solid;
        border-color: #ababb2;
        margin-bottom: 0 !important;
      }
  
      .input-group__col{
        gap: 1rem;
        input{
          width: calc(65% - 1rem);
          max-width: calc(65% - 1rem);
        }
        select{
          width: 35%;
          max-width: 35%;
        }
      }
  
      .label {
        // min-width: 10rem;
        font-weight: bold;
        padding: 12px 12px 12px 0;
      }
  
      .data {
        .inpt {
          color: var(--c_black);
          outline: none;
          -webkit-appearance: none;
        }
      }
  
      .invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
  
      // .form-control {
      //   width: 100%;
      //   height: 100%;
      //   color: #ffffff;
      //   background-color: #1C2126;
      //   padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      //   margin: auto 0rem;
      //   border: 1px solid #2F363D;
      //   border-radius: 0.75rem;
      //   box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
      //   outline: none;
      //   -webkit-appearance: none;
      // }
    }
  
    &__footer {
      padding: 24px;
      .btn {
        font-size: 18px;
        padding: 1rem 3rem;
        margin: 0;
        background: #1763fb;
        box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
        border-radius: 6px;
      }
    }
  }
  .block {
    margin-bottom: 16px;
    &_check {
  
      .ttl {
        padding-bottom: .25rem;
      }
  
      ::v-deep .checkBox {
        .label {
          font-size: 6px;
          font-weight: 400;
        }
      }
    }
  
    &_files {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
      gap: 24px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      .box_addFiles {
        padding: 1rem 1.75rem;
        background: transparent;
        box-shadow: none;
        border: 1px solid #ababb2;
        display: flex;
        flex-direction: column;
        margin: 0;
        border-radius: 14px;
        width: 100%;
        .ttl {
          color: #09090b;
        }
  
        .separ {
          width: 3rem;
          height: .25rem;
          background: #1763fb;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          border-radius: 6px;
          margin: .25rem 0;
          display: none !important;
        }
  
        .content {
          min-height: 8rem;
          flex-grow: 1;
          margin-top: 1rem;
          border: 1px dashed #1763fb;
          box-sizing: border-box;
          filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
          border-radius: 6px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .choose {
            text-align: center;
  
            .btn {
              padding: 1rem 1.75rem;
              margin: .5rem;
              background: #1763fb;
              border-radius: 6px;
              color: #fff;
              line-height: 1.1;
            }
  
            .desc {
              font-size: 12px;
              color: #09090b;
            }
          }
  
          &_many {
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0.75rem 6px;
  
            .draggable {
              display: flex;
              flex-wrap: wrap;
              padding: 0;
            }
  
            .file {
              position: relative;
              text-align: center;
              padding: .75rem .5rem .5rem;
              width: 6.25rem;
              max-width: 6.25rem;
  
              svg.img {
                width: 3rem;
                height: 3rem;
                margin: 0 auto;
              }
  
              .btn {
                &_del {
                  position: absolute;
                  top: 0;
                  right: 1rem;
                  width: 1.25rem;
                  height: 1.25rem;
                  border-radius: 50%;
                  background-color: #B03636;
                  display: flex;
                  align-items: center;
                  justify-content: center;
  
                  svg.img {
                    width: 40%;
                    height: 40%;
                  }
                }
              }
  
              .desc {
                font-size: 10px;
                color: #09090b;
                word-break: break-word;
              }
            }
  
            .add {
              text-align: center;
              padding: 0 .5rem .5rem;
              width: 6rem;
              max-width: 6rem;
  
              .btn {
                width: 2.5rem;
                height: 2.5rem;
                margin: .75rem auto .25rem;
                border-radius: 50%;
                background: #1763fb;
                display: flex;
                align-items: center;
                justify-content: center;
  
                svg.img {
                  width: 45%;
                  height: 45%;
                  transform: rotate(45deg);
                }
              }
  
              .desc {
                font-size: 12px;
                color: #09090b;
              }
            }
          }
        }
      }
    }
  }
  </style>
  