<template>
  <div class="page page-you-offers">
    <div class="box" v-if="license_offers.length">
      <LicenseOffer 
      v-for="(item, index) in license_offers" 
      :key="index"
      :offer="item"
      :offer-sequense="index"
      @editLicenseOffer="editLicenseOffer(item)"
      @spliceOfferItem="spliceOfferItem(index)" />
    </div>
    <div class="empty-page__wrapper" v-else>
      <center>
        <h2>
          You have not created any request yet
        </h2>
      </center>
      <router-link :to="{ name: 'Request' }" class="btn link_btn">
        Create now
      </router-link>
    </div>
  </div>
</template>

<script>
import LicenseOffer from '@/components/elem/LicenseOffer'

export default {
  components: {
    LicenseOffer,
  },
  data: () => ({
    license_offers: [],
  }),
  computed: {
    
  },
  methods: {
    getLicenseOffers(){
      const that = this

      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'license-offers')
        .then(function (response) {
          that.license_offers = response.data.data
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    editLicenseOffer(offer){
      this.$router.push({ name: 'EditRequest', params: { id: offer.id } });
    },
    spliceOfferItem(index){
      this.license_offers.splice(index, 1);
    }
  },
  created () {
    this.getLicenseOffers()
  },
  watch: {
    
  }
}
</script>

<style lang="scss" scoped>
.page-you-offers {
  .box{
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 24px;
    box-sizing: border-box;
    background: transparent;
    box-shadow: none;
    flex-wrap: wrap;
  }
  .link_btn{
    display: flex;
    flex: 0 0 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 2.125rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.3125rem;
    border: 0.125rem solid #1763fb;
    background: #1763fb;
    color: #fff;
    padding: 8px 16px;
  }
  .empty-page__wrapper{
    h2{
      color: #09090b;
    }
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
