<template>
  <div class="auth-wrapper">
    <slot/>
  </div>
</template>

<script>
 
export default {
	name: 'AuthLayout',
	data () {
		return {
			title: '',
			metaData: []
		}
	},
	created () {
		this.getMetaTags()
	},
	methods: {
		async getMetaTags () {
			await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'meta/users-cabinet')
		        .then(({ data }) => {
		          for (let i=0; i < data.length; i++) {
		          	if (data[i].name == 'title') {
		          		this.title = data[i].value
		          	} else {
		          		if (data[i].value) {
			          		if (data[i].property) {
			          			this.metaData.push({
				          			name: data[i].name,
				          			content: data[i].value,
				          			property: data[i].property,
				          		})
			          		} else {
			          			this.metaData.push({
				          			name: data[i].name,
				          			content: data[i].value,
				          		})
			          		} 
		          		}
		          	}
		          }
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		}
	},
	metaInfo() {
        return { 
            title: `${this.title}`, 
            meta: this.metaData    
        }
    }
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
	background-color: #fff !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	position: relative;
}
</style>